<template>
  <div :class="['content-container', isAuth ? 'content-container--auth' : '']">
    <BaseH1 :text="'Wszystko OK'" :short="true" />
    <TextDescription v-html="description" />
    <ButtonBasic
      v-if="isAuth"
      :to="ROUTE_LIST_NANNY"
      :label="'Przejdź do serwisu'"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { ROUTE_LIST_NANNY_NAME } from "@/router/constants";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonBasic from "@/components/UI/Base/ButtonBasic.vue";

const ROUTE_LIST_NANNY = {
  name: ROUTE_LIST_NANNY_NAME,
};

export default {
  components: {
    BaseH1,
    TextDescription,
    ButtonBasic,
  },

  setup() {
    const userStore = useUserStore();
    const { isAuth } = storeToRefs(userStore);

    const description = computed(() => {
      if (!isAuth.value) {
        return "Twój adres email jest już \nzweryfikowany. \nZaloguj się,\naby korzystać z serwisu";
      }
      return "Twój adres email jest już\nzweryfikowany";
    });

    return {
      description,
      ROUTE_LIST_NANNY,
      isAuth,
    };
  },
};
</script>

<style scoped>
.content-container {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 160px;
}
.content-container--auth {
  row-gap: 80px;
}

@media (max-width: 1200px) {
  .content-container {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 160px;
  }
  .content-container--auth {
    row-gap: 80px;
  }
}
</style>
